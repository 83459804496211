/*----------------
PMP
----------------*/
@font-face {
  font-family: 'KosugiMaru';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/family/KosugiMaru-Regular.ttf') format('truetype');
}
.c-header,
.base-topNav,
.base-topRanking,
.base-topRecommend,
.base-topCategory,
.base-topFeature,
.base-tourList,
.c-breadcrumbs,
.base-feature,
.base-point,
.base-sectionItem,
.base-ranking,
.base-recommend,
.base-related,
.c-card {
  font-family: 'KosugiMaru', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
}
.c-header {
  position: relative;
  z-index: 1;
}
.base-ranking,
.base-recommend,
.base-related {
  color: var(--theme-text-color);
}

.widget-calendar {
  width: 100%;
  /* max-width: 704px; */
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  margin: 0 auto;
  background: var(--theme-content-section-background-color);
}
.widget-calendar__month__week__priceSpan {
  font-size: 10px !important;
  letter-spacing: -0.075em !important;
}
label.fully_booked p {
  color: var(--theme-text-color) !important;
}
p.fully_booked {
  background: var(--theme-text-color) !important;
}
/*----------------
sns
----------------*/
@media screen and (max-width: 768px) {
  .c-sns {
    padding: 0;
  }
}
/*----------------
bookmark
----------------*/
.maininfo__other__bookmark {
  margin-right: 8px;
}
.maininfo__other__bookmark label {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.maininfo__other__bookmark input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.maininfo__other__bookmark img {
  width: 24px;
  height: 24px;
}
.maininfo__other__bookmark input ~ .on {
  display: none;
}
.maininfo__other__bookmark input:checked ~ .on {
  display: block;
}
.maininfo__other__bookmark input ~ .off {
  display: block;
}
.maininfo__other__bookmark input:checked ~ .off {
  display: none;
}
.maininfo__other__bookmark p {
  font-size: 14px;
}

/*----------------
review
----------------*/
.c-review {
  background: transparent;
  box-shadow: none;
  border-radius: 16px;
  border: 2px dashed var(--theme-primary-color);
}
.c-review__ttl {
  width: calc(100% - 32px);
  padding: 16px 0;
  margin: 0 auto;
}
.c-review__summary__total.ac.is-close .c-review__close::before,
.c-review__search__ttl.ac.is-close .c-review__close::before,
.c-review__summary__total.ac.is-close .c-review__close::before,
.c-review__search__ttl.ac.is-close .c-review__close::after {
  background: var(--theme-primary-color);
}

.c-review__summary__star__list__item__bar span {
  background: var(--theme-primary-color);
}
.c-review__search__main__item__checkbox input:checked {
  border-color: var(--theme-primary-color);
}
.c-review__search__main__item__checkbox input:checked ~ p {
  border-color: var(--theme-primary-color);
  background: var(--theme-primary-color);
}
.c-review__search__main__submit {
  cursor: pointer;
  border-radius: 20px;
  background: var(--theme-primary-color);
}
.c-review__list__comment__item__top__info__type {
  color: var(--theme-primary-color);
  border: 1px solid var(--theme-primary-color);
  border-radius: 16px;
  font-weight: normal;
}
.c-review__list__comment__item__more a,
.c-review__list__comment__item__reply__more a {
  color: var(--theme-primary-color);
}
.c-review__list__comment__item__user__ic {
  color: var(--theme-primary-color);
}
.c-review__list__comment__item__pic__prev::after,
.c-review__list__comment__item__pic__next::after {
  border-left-color: var(--theme-primary-color);
  border-bottom-color: var(--theme-primary-color);
}
.c-review__list__btn {
  border-radius: 24px;
  background: var(--theme-primary-color);
}
.c-review__modal__content__header__close::before,
.c-review__modal__content__header__close::after {
  background: var(--theme-primary-color);
}
@media screen and (max-width: 768px) {
  .c-review {
    border: none;
    width: calc(100% + 32px) !important;
    margin-left: -16px;
    margin-bottom: 64px;
  }
  .c-review__ttl {
    width: 100%;
    padding: 16px;
  }
}
