@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap');

/* base theme overrides for Pacific theme */

.base_pacific__3Kqme {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
}
.base_pacific-rotate__VygUg {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
  font-weight: 500 !important;
  line-height: 1.05;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}
.base_semi-big-text__Z03_W {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 2rem;
}
.base_base-single__card__EI0om {
  border-radius: 16px;
}
.base_base-single__section__title__0lYba {
  border: none;
}
.base_base-single__section__title_jp__cAqyq {
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;
}
.base_base-single__headerinfo__4Ewnz {
  border: none;
  padding: 0 16px;
}
.base_base-single__2ndheaderinfo__KatNG {
  padding: 0 16px;
}
.base_base-single__title__yZqXP {
  padding: 16px;
}
.base_c-tag__zgcWa {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.base_c-tag__zgcWa li {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: rgba(32, 32, 32, 1);
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 4px;
  margin-right: 4px;
}
.base_base-headline__78ZV1 {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
}
.base_base-font__YrcKd {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
}
@media screen and (max-width: 767px) {
  .base_base-single__card__EI0om {
    border-radius: 0;
    margin-top: 0;
  }
  .base_base-single__headerinfo__4Ewnz {
    order: 1;
    padding: 8px 0px 0px 0px;
  }
  .base_base-single__title__h1__7sGta {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  /* Single */
  .base_base-subHeader__back__ic__bu6_8 {
    width: 16px;
    height: 16px;
  }
  .base_base-subHeader__back__mVvRj {
    font-size: 12px;
  }
  .base_c-tag__zgcWa {
    margin-top: 0;
    margin-bottom: 16px;
    padding: 0 16px;
  }
  .base_c-tag__zgcWa li {
    font-size: 10px;
  }
}

.base_base-wrap__BHu2I {
  width: 100%;
  position: relative;
}
.base_base-main__60OdB {
  margin-bottom: 128px;
}
.base_base-inner__qPROZ {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
}
.base_base-headline__RogCT {
  margin-top: calc(48px + 16px);
  margin-bottom: 48px;
}
.base_base-headline__RogCT p {
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}
.base_base-singleTop__VtrjI {
}
.base_base-column__D1d85 {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.base_base-column__main__14pwO {
  width: calc(100% - 320px - 32px);
}
.base_price__EzeZ8 .base_base-column__main__14pwO {
  width: calc(100% - 400px - 32px);
}
.base_base-column__main__section__nb0ml {
}
.base_base-column__main__section__nb0ml + .base_base-column__main__section__nb0ml {
  margin-top: 64px;
}
.base_base-column__ttl__GBc5A {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base_base-column__ttl__GBc5A::before,
.base_base-column__ttl__GBc5A::after {
  content: '';
  width: 160px;
  height: 1px;
  background: #dbd4ce;
  flex-shrink: 0;
}

.base_base-column__ttl__GBc5A h2 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
.base_base-column__ttl__GBc5A p {
  font-size: 14px;
  font-family: 'Raleway';
  text-align: center;
}
.base_base-column__calendar__z1zxA {
  width: 320px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
}
.base_price__EzeZ8 .base_base-column__calendar__z1zxA {
  width: 400px;
}
.base_base-column__spFixed__fUxkS {
  display: none;
}
.base_breadcrumb-link__MH_rZ {
  color: #aaa;
}

@media screen and (max-width: 900px) {
  .base_price__EzeZ8 .base_base-headline__RogCT {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base_price__EzeZ8 .base_base-singleTop__VtrjI {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base_price__EzeZ8 .base_base-column__D1d85 {
    margin-top: 16px;
    display: block;
    padding: 0;
  }
  .base_price__EzeZ8 .base_base-column__main__14pwO {
    width: 100%;
    padding: 0 16px;
  }
  .base_price__EzeZ8 .base_base-column__main__section__nb0ml + .base_base-column__main__section__nb0ml {
    margin-top: 32px;
  }
  .base_price__EzeZ8 .base_base-column__ttl__GBc5A {
    margin-bottom: 16px;
  }
  .base_price__EzeZ8 .base_base-column__ttl__GBc5A::before,
  .base_price__EzeZ8 .base_base-column__ttl__GBc5A::after {
    width: 32px;
  }
  .base_price__EzeZ8 .base_base-column__ttl__GBc5A h2 {
    font-size: 20px;
  }
  .base_price__EzeZ8 .base_base-column__ttl__GBc5A p {
    font-size: 12px;
  }
  .base_price__EzeZ8 .base_base-column__calendar__z1zxA {
    width: 100%;
    padding: 16px;
    background: inherit;
  }
  .base_price__EzeZ8 .base_base-column__spFixed__fUxkS {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base_price__EzeZ8 .base_base-column__spFixed__fUxkS::before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base_price__EzeZ8 .base_base-column__spFixed__fUxkS::after {
    content: '';
    width: 100%;
    height: 50%;
    background: #f9f9f9;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base_price__EzeZ8 .base_base-column__spFixed__btn__rqPjv {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .base_base-headline__RogCT {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base_base-singleTop__VtrjI {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base_base-column__D1d85 {
    margin-top: 16px;
    display: block;
    padding: 0;
  }
  .base_base-column__main__14pwO {
    width: 100%;
    padding: 0 16px;
  }
  .base_base-column__main__section__nb0ml + .base_base-column__main__section__nb0ml {
    margin-top: 32px;
  }
  .base_base-column__ttl__GBc5A {
    margin-bottom: 16px;
  }
  .base_base-column__ttl__GBc5A::before,
  .base_base-column__ttl__GBc5A::after {
    width: 32px;
  }
  .base_base-column__ttl__GBc5A h2 {
    font-size: 20px;
  }
  .base_base-column__ttl__GBc5A p {
    font-size: 12px;
  }
  .base_base-column__calendar__z1zxA {
    position: relative;
    top: 0;
    margin: 32px auto 0 auto;
  }
  .base_base-column__spFixed__fUxkS {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base_base-column__spFixed__fUxkS::before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base_base-column__spFixed__fUxkS::after {
    content: '';
    width: 100%;
    height: 50%;
    background: #f9f9f9;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base_base-column__spFixed__btn__rqPjv {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
}

.base_color-txt__j2nq_ {
  color: #2e1f12 !important;
}
.base_color-txt--white___Rh9Y {
  color: #fff !important;
}
.base_color-bg__ANIhf {
  background: #f9f9f9;
  background: var(--theme-background-color);
}
.base_color-bg--white__Dcp_3 {
  background: #fff;
  background: var(--theme-header-background-color);
}
.base_color-bg--black__QJvPt {
  background: #2e1f12;
  background: var(--theme-footer-background-color);
}
.base_color-bor--white__gJn4N {
  border-color: #fff;
  border-color: var(--theme-header-background-color);
}
.base_color-bor--black__Ny34_ {
  border-color: #2e1f12;
  border-color: var(--theme-footer-background-color);
}
/* color change */
.base_color-main--txt__qmehl {
  color: #bb0000;
  color: var(--theme-primary-color);
}
.base_color-sub--txt__z6BTe {
  color: #ab5411;
  color: var(--theme-secondary-color);
}
.base_color-main--bor__YKr2t {
  border-color: #bb0000;
  border-color: var(--theme-primary-color);
}
.base_color-sub--bor__oOg60 {
  border-color: #ab5411;
  border-color: var(--theme-secondary-color);
}
.base_color-main--bg__1AiQK {
  background: #bb0000;
  background: var(--theme-primary-color);
}
.base_color-sub--bg__FHdsj {
  background: #ab5411;
  border-color: var(--theme-secondary-color);
}

/*------------------------------------------------------------------------------
utility
------------------------------------------------------------------------------*/
.base_base-padding__8-8__8M_Rn {
  padding: 8px;
}
.base_base-padding__8-16__dmPSf {
  padding: 8px 16px;
}
.base_base-padding__16-8__hOUsC {
  padding: 16px 8px;
}
.base_base-padding-16__cnyCO {
  padding: 16px;
}
.base_base-padding-16-32__5qKBv {
  padding: 16px 32px;
}
.base_base-padding-32-16__pL93n {
  padding: 32 16px;
}

.base_base-marginTop-8__DBqKX {
  margin-top: 8px;
}
.base_base-marginTop-16__TkFoV {
  margin-top: 16px;
}
.base_base-marginTop-32__KXcip {
  margin-top: 32px;
}

.base_base-marginBottom-8__ACO8_ {
  margin-bottom: 8px;
}
.base_base-marginBottom-16__sIFhe {
  margin-bottom: 16px;
}
.base_base-marginBottom-32__SIOn8 {
  margin-bottom: 32px;
}

@font-face {
  font-family: 'KosugiMaru';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/family/KosugiMaru-Regular.ttf') format('truetype');
}
.base_base-theme__d9D62 {
  font-family: "KosugiMaru", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 16px;
  color: var(--theme-text-color);
}
.base_base-main__WKhVQ {
  margin-bottom: 64px;
}
.base_base-inner__hK8jA {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 32px;
}
.base_base-headline__40e47 {
  margin-top: calc(48px + 16px);
  margin-bottom: 48px;
}
.base_base-headline__40e47 p {
  font-family: 'Raleway' , "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}
.base_base-column__hmiQx {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.base_base-column__main__YmfRY {
  width: calc(100% - 320px - 32px);
}
.base_price__Cwo9_ .base_base-column__main__YmfRY {
  width: calc(100% - 400px - 32px);
}
.base_base-column__main__section__Ka_1f + .base_base-column__main__section__Ka_1f {
  margin-top: 64px;
}
.base_base-column__ttl__LZ6B2 {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.base_base-column__ttl__LZ6B2::after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: var(--theme-primary-color);
  position: absolute;
  left: 0;
  bottom: 0;
}
.base_base-column__ttl__ic__kv_O7 {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 8px solid var(--theme-primary-color);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.base_base-column__ttl__ic__kv_O7::after {
  content: "";
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: var(--theme-primary-color);
}
.base_base-column__ttl__body__qoOjm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base_base-column__ttl__body__main__fYiHj {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.base_base-column__ttl__body__main__fYiHj h2 {
  font-size: 32px;
  display: inline;
  color: var(--theme-subheader-color);
  background-image: linear-gradient(transparent 60%, var(--theme-secondary-color) 0%);
}
.base_base-column__ttl__body__sub__C9zIU {
  font-size: 16px;
  margin-left: auto;
}
.base_base-column__calendar__atxPo {
  width: 320px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
}
.base_price__Cwo9_ .base_base-column__calendar__atxPo {
  width: 400px;
}
.base_base-column__spFixed__xk6CP { display: none; }

@media screen and (max-width: 900px) {
  .base_price__Cwo9_ .base_base-inner__hK8jA {
    padding: 0 16px;
  }
  .base_price__Cwo9_ .base_base-headline__40e47 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base_price__Cwo9_ .base_base-singleTop__RwgoG {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base_price__Cwo9_ .base_base-column__hmiQx {
    margin-top: 16px;
    display: block;
  }
  .base_price__Cwo9_ .base_base-column__main__YmfRY {
    width: 100%;
  }
  .base_price__Cwo9_ .base_base-column__main__section__Ka_1f + .base_base-column__main__section__Ka_1f {
    margin-top: 32px;
  }
  .base_price__Cwo9_ .base_base-column__ttl__LZ6B2 {
    margin-bottom: 16px;
  }
  .base_price__Cwo9_ .base_base-column__ttl__body__qoOjm {
    display: block;
  }
  .base_price__Cwo9_ .base_base-column__ttl__body__main__fYiHj h2 {
    font-size: 24px;
  }
  .base_price__Cwo9_ .base_base-column__calendar__atxPo {
    width: 100%;
    position: relative;
    top: 0;
    margin: 32px auto 0 auto;
    background: inherit;
  }
  .base_price__Cwo9_ .base_base-column__spFixed__xk6CP {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base_price__Cwo9_ .base_base-column__spFixed__xk6CP::before {
    content: "";
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,var(--theme-background-color) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base_price__Cwo9_ .base_base-column__spFixed__xk6CP::after {
    content: "";
    width: 100%;
    height: 50%;
    background: var(--theme-background-color);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base_price__Cwo9_ .base_base-column__spFixed__btn__DT5_F {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: #fff;
    border-radius: 24px;
    background: var(--theme-primary-color);
  }
}

@media screen and (max-width: 768px) {
  .base_base-inner__hK8jA {
    padding: 0 16px;
  }
  .base_base-headline__40e47 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base_base-singleTop__RwgoG {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base_base-column__hmiQx {
    margin-top: 16px;
    display: block;
  }
  .base_base-column__main__YmfRY {
    width: 100%;
  }
  .base_base-column__main__section__Ka_1f + .base_base-column__main__section__Ka_1f {
    margin-top: 32px;
  }
  .base_base-column__ttl__LZ6B2 {
    margin-bottom: 16px;
  }
  .base_base-column__ttl__body__qoOjm {
    display: block;
  }
  .base_base-column__ttl__body__main__fYiHj {
    
  }
  .base_base-column__ttl__body__main__fYiHj h2 {
    font-size: 24px;
  }
  .base_base-column__calendar__atxPo {
    width: 100%;
    position: relative;
    top: 0;
    margin: 32px auto 0 auto;
  }
  .base_base-column__spFixed__xk6CP {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base_base-column__spFixed__xk6CP::before {
    content: "";
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,var(--theme-background-color) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base_base-column__spFixed__xk6CP::after {
    content: "";
    width: 100%;
    height: 50%;
    background: var(--theme-background-color);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base_base-column__spFixed__btn__DT5_F {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: #fff;
    border-radius: 24px;
    background: var(--theme-primary-color);
  }
}


/*------------------------------------------------------------------------------
utility
------------------------------------------------------------------------------*/
.base_base-padding__8-8__ojma1 { padding: 8px; }
.base_base-padding__8-16__8UZ0m { padding: 8px 16px; }
.base_base-padding__16-8___cwgv { padding: 16px 8px; }
.base_base-padding-16__jmt46 { padding: 16px; }
.base_base-padding-16-32__uBIvV { padding: 16px 32px; }
.base_base-padding-32-16__AiE4l { padding: 32 16px; }

.base_base-marginTop-8__W2mes { margin-top: 8px; }
.base_base-marginTop-16__nds_W { margin-top: 16px; }
.base_base-marginTop-32__Tn2Ra { margin-top: 32px; }

.base_base-marginBottom-8__HpQE_ { margin-bottom: 8px; }
.base_base-marginBottom-16__RhiGP { margin-bottom: 16px; }
.base_base-marginBottom-32__c_6vB { margin-bottom: 32px; }




.overrides_widget-calendar__LH64Q {
  width: 100%;
  max-width: 704px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  margin: 0 auto;
}

/*----------------
PMP
----------------*/
@font-face {
  font-family: 'KosugiMaru';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/family/KosugiMaru-Regular.ttf') format('truetype');
}
.overrides_c-header__AwAsY,
.overrides_base-topNav__WXMNu,
.overrides_base-topRanking__8t68i,
.overrides_base-topRecommend___qt2J,
.overrides_base-topCategory__eEfJm,
.overrides_base-topFeature__5JPtT,
.overrides_base-tourList__j9V4g,
.overrides_c-breadcrumbs__FfYC5,
.overrides_base-feature__7DQk7,
.overrides_base-point__6dAeE,
.overrides_base-sectionItem__7O884,
.overrides_base-ranking__sE04b,
.overrides_base-recommend__12mP3,
.overrides_base-related__29lvm,
.overrides_c-card__g3DHv {
  font-family: 'KosugiMaru', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
}
.overrides_c-header__AwAsY {
  position: relative;
  z-index: 1;
}
.overrides_base-ranking__sE04b,
.overrides_base-recommend__12mP3,
.overrides_base-related__29lvm {
  color: var(--theme-text-color);
}

.overrides_widget-calendar__d_Cas {
  width: 100%;
  /* max-width: 704px; */
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  margin: 0 auto;
  background: var(--theme-content-section-background-color);
}
.overrides_widget-calendar__month__week__priceSpan__2yr_I {
  font-size: 10px !important;
  letter-spacing: -0.075em !important;
}
label.overrides_fully_booked__GrYoA p {
  color: var(--theme-text-color) !important;
}
p.overrides_fully_booked__GrYoA {
  background: var(--theme-text-color) !important;
}
/*----------------
sns
----------------*/
@media screen and (max-width: 768px) {
  .overrides_c-sns__hFmT2 {
    padding: 0;
  }
}
/*----------------
bookmark
----------------*/
.overrides_maininfo__other__bookmark__R0Oy4 {
  margin-right: 8px;
}
.overrides_maininfo__other__bookmark__R0Oy4 label {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.overrides_maininfo__other__bookmark__R0Oy4 input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.overrides_maininfo__other__bookmark__R0Oy4 img {
  width: 24px;
  height: 24px;
}
.overrides_maininfo__other__bookmark__R0Oy4 input ~ .overrides_on__Qn5co {
  display: none;
}
.overrides_maininfo__other__bookmark__R0Oy4 input:checked ~ .overrides_on__Qn5co {
  display: block;
}
.overrides_maininfo__other__bookmark__R0Oy4 input ~ .overrides_off__5IPC3 {
  display: block;
}
.overrides_maininfo__other__bookmark__R0Oy4 input:checked ~ .overrides_off__5IPC3 {
  display: none;
}
.overrides_maininfo__other__bookmark__R0Oy4 p {
  font-size: 14px;
}

/*----------------
review
----------------*/
.overrides_c-review__J1R0X {
  background: transparent;
  box-shadow: none;
  border-radius: 16px;
  border: 2px dashed var(--theme-primary-color);
}
.overrides_c-review__ttl__P6qwn {
  width: calc(100% - 32px);
  padding: 16px 0;
  margin: 0 auto;
}
.overrides_c-review__summary__total__X2BWR.overrides_ac__5ctX2.overrides_is-close__ChGJo .overrides_c-review__close__TM94d::before,
.overrides_c-review__search__ttl__8zZFa.overrides_ac__5ctX2.overrides_is-close__ChGJo .overrides_c-review__close__TM94d::before,
.overrides_c-review__summary__total__X2BWR.overrides_ac__5ctX2.overrides_is-close__ChGJo .overrides_c-review__close__TM94d::before,
.overrides_c-review__search__ttl__8zZFa.overrides_ac__5ctX2.overrides_is-close__ChGJo .overrides_c-review__close__TM94d::after {
  background: var(--theme-primary-color);
}

.overrides_c-review__summary__star__list__item__bar__kcwTk span {
  background: var(--theme-primary-color);
}
.overrides_c-review__search__main__item__checkbox__xUb_r input:checked {
  border-color: var(--theme-primary-color);
}
.overrides_c-review__search__main__item__checkbox__xUb_r input:checked ~ p {
  border-color: var(--theme-primary-color);
  background: var(--theme-primary-color);
}
.overrides_c-review__search__main__submit__fjWKF {
  cursor: pointer;
  border-radius: 20px;
  background: var(--theme-primary-color);
}
.overrides_c-review__list__comment__item__top__info__type__Ch_2N {
  color: var(--theme-primary-color);
  border: 1px solid var(--theme-primary-color);
  border-radius: 16px;
  font-weight: normal;
}
.overrides_c-review__list__comment__item__more__e_Lpi a,
.overrides_c-review__list__comment__item__reply__more__KqRDj a {
  color: var(--theme-primary-color);
}
.overrides_c-review__list__comment__item__user__ic__uCePI {
  color: var(--theme-primary-color);
}
.overrides_c-review__list__comment__item__pic__prev__iBHLU::after,
.overrides_c-review__list__comment__item__pic__next__jhFro::after {
  border-left-color: var(--theme-primary-color);
  border-bottom-color: var(--theme-primary-color);
}
.overrides_c-review__list__btn__bhzva {
  border-radius: 24px;
  background: var(--theme-primary-color);
}
.overrides_c-review__modal__content__header__close__bXd_X::before,
.overrides_c-review__modal__content__header__close__bXd_X::after {
  background: var(--theme-primary-color);
}
@media screen and (max-width: 768px) {
  .overrides_c-review__J1R0X {
    border: none;
    width: calc(100% + 32px) !important;
    margin-left: -16px;
    margin-bottom: 64px;
  }
  .overrides_c-review__ttl__P6qwn {
    width: 100%;
    padding: 16px;
  }
}

